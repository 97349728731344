<template>
  <div class="user-list-display">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/dashboard/overview' }">数据概览</el-breadcrumb-item>
      <el-breadcrumb-item>用户列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-search">
      <div class="left">
        <el-input placeholder="请输入用户手机号或昵称" v-model="query.title" clearable @clear="getListData"></el-input>
        <el-button type="primary" @click="getListData">搜索</el-button>
      </div>
    </div>
    <div class="page-content">
      <el-row>
        <el-table :data="dataList" border style="width: 100%">
          <el-table-column prop="id" label="ID" width="80"></el-table-column>
          <el-table-column prop="nick" label="昵称" width="120"></el-table-column>
          <el-table-column prop="avatar" label="头像" width="100">
            <template slot-scope="scope">
              <el-image style="width: 60px; height: 60px" :src="scope.row.avatar" fit="cover"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="姓名" width="180"></el-table-column>
          <el-table-column prop="phone" label="电话" width="180"></el-table-column>
          <el-table-column prop="relation" label="与患者关系" width="100"></el-table-column>
          <el-table-column prop="hospital_title" label="所在医院"></el-table-column>
          <el-table-column prop="area_title" label="所在病区"></el-table-column>
          <el-table-column label="操作" width="180">
            <template slot-scope="scope">
              <el-button type="primary" icon="el-icon-view" @click="viewItem(scope.row)" size="small"></el-button>
              <el-button type="danger" icon="el-icon-delete" @click="deleteItem(scope.row.id)" size="small"></el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="table-pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current" :page-sizes="[30, 100, 200, 400]" :page-size="query.size" layout="total, sizes, prev, pager, next, jumper" :total="query.total"></el-pagination>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="68%" :before-close="handleClose">
      <el-descriptions title="用户信息">
        <el-descriptions-item label="openid">{{ userInfo.openid }}</el-descriptions-item>
        <el-descriptions-item label="昵称">{{ userInfo.nick }}</el-descriptions-item>
        <el-descriptions-item label="头像">
          <el-image style="width: 60px; height: 60px" :src="userInfo.avatar" fit="cover"></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="姓名">{{ userInfo.name }}</el-descriptions-item>
        <el-descriptions-item label="电话">{{ userInfo.phone }}</el-descriptions-item>
        <el-descriptions-item label="与患者关系">{{ userInfo.relation }}</el-descriptions-item>
        <el-descriptions-item label="患者姓名">{{ userInfo.patientname }}</el-descriptions-item>
        <el-descriptions-item label="病床号">{{ userInfo.sickbed }}</el-descriptions-item>
        <el-descriptions-item label="所在医院">{{ userInfo.hospital_title }}</el-descriptions-item>
        <el-descriptions-item label="所在病区">{{ userInfo.area_title }}</el-descriptions-item>
        <el-descriptions-item label="注册时间">{{ moment(userInfo.creat_time * 1000).format('YYYY-MM-DD HH:mm:ss') }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'UserListDisplay',
  data () {
    return {
      dialogVisible: false,
      query: {
        title: '',
        size: 30,
        current: 1,
        total: 0
      },
      dataList: [],
      userInfo: {
        openid: '',
        nick: '',
        avatar: '',
        name: '',
        phone: '',
        relation: '',
        patientname: '',
        sickbed: '',
        hospital_title: '',
        area_title: '',
        creat_time: 0
      }
    }
  },
  created () {
    this.getListData()
  },
  methods: {
    async getListData () {
      const { data: res } = await this.$http.get('/user-list', { params: this.query })
      if (res.meta.status === 200) {
        this.dataList = res.data.list
        this.query.current = res.data.current
        this.query.total = res.data.total
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleSizeChange (val) {
      this.query.size = val
    },
    handleCurrentChange (val) {
      this.query.current = val
    },
    viewItem (item) {
      console.log(item)
      this.userInfo = item
      this.dialogVisible = true
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('/user/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        await this.getListData()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleClose (done) {
      done()
    }
  }
}
</script>

<style scoped>
.user-list-display{}
.table-pagination{
  padding: 20px 0;
  text-align: center;
}
.page-search{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.page-search .left{
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-search .left .el-button{
  margin-left: 14px;
}
</style>
